import styled, { css } from 'styled-components';

import { getThemeColor, getTypographyStyle, media } from '@utils/styled';

import { OFFER_TYPES } from '@common/constants/filters';
import { LAYOUT_COLUMN } from '@common/constants/ui';
import { IconButton } from '@common/components/IconButton';
import Typography from '@common/components/Typography';
import LazyImg from '@common/components/LazyImg';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 5)}
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 4)}
  background-color: ${getThemeColor('bluegrey.95')};

  ${({ theme }) =>
    theme.layoutPreference === LAYOUT_COLUMN &&
    css`
      ${media.from480down`
      ${theme.getFluidSpacing('padding-left', 'widthBased', 0)};
      ${theme.getFluidSpacing('padding-right', 'widthBased', 0)};
    `}
    `}
`;

export const FavoriteButton = styled(IconButton)`
  z-index: 3;
  cursor: pointer !important;
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 5)}
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 5)}
  ${({ theme }) =>
    theme.layoutPreference === LAYOUT_COLUMN &&
    css`
      ${media.from480down`
      margin-right: 0;
  `}
    `};
`;

export const Price = styled(Typography).attrs(({ theme }) => ({
  customAs: 'p',
  variant: theme.layoutPreference === LAYOUT_COLUMN ? 'h4' : 'paragraph2',
}))`
  margin: 0;
  font-weight: 700;
  ${({ offerType, theme }) =>
    offerType === OFFER_TYPES.SALE && `color: ${getThemeColor('hue.red')({ theme })}`};

  ${media.from480up`
    ${getTypographyStyle('h4')};
  `};
`;

const withLayoutPreference = ({ theme }) => css`
  grid-template-rows: ${theme.layoutPreference === LAYOUT_COLUMN ? 'auto' : '2.8rem'} auto auto;
  grid-template-areas: ${theme.layoutPreference === LAYOUT_COLUMN ?
    `
    "product-badge favorite-button"
    "product-image product-image"
  ` :
    `
    "product-badge product-badge"
    "product-image product-image"
    "favorite-button favorite-button"
  `};
`;

export const ProductTop = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr);
  ${withLayoutPreference};

  button {
    grid-area: favorite-button;
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 5)};
  }

  ${media.from480up`
    grid-template-rows: 4rem auto;
    grid-template-areas:
      "product-badge favorite-button"
      "product-image product-image";

    button {
      margin-bottom: 0;
    }
  `}
`;

export const ProductBottom = styled.div`
  color: ${getThemeColor('bluegrey.15')};
  text-decoration: none;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 3)}
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 5)}
  ${({ theme, isOutdated }) => isOutdated && theme.getFluidSpacing('padding-bottom', 'scale', 4)}
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 5)}

  ${({ theme }) =>
    theme.layoutPreference === LAYOUT_COLUMN &&
    css`
      ${media.from480down`
      padding-left: 0;
      padding-right: 0;
  `}
    `};

  &:hover {
    text-decoration: none;
  }
`;

export const ProductLink = styled.a`
  display: block;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

export const ProductImage = styled(LazyImg)`
  text-align: center;

  @supports not (aspect-ratio: 1/1) {
    picture {
      width: 100%;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  grid-area: product-image;
`;

export const OfferPillWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 5)}
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 5)}
`;
