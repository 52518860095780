import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

import * as favoritesActions from '@actions/favorites';
import * as authSelectors from '@selectors/auth';
import * as favoritesSelectors from '@selectors/favorites';
import * as favoritesThunks from '@thunks/favorites';

import {
  measureAddToFavorites,
  measureProductCardView,
  measureProductClick,
} from '@utils/analytics';

import { OFFER_TYPES } from '@common/constants/filters';
import ProductBox from '@common/components/ProductBox';
import Modal from '@common/components/Modal';
import ConfirmationDialog from '@common/components/Modal/ConfirmationDialog';

export function Product({
  publishedUntil,
  productColorId,
  image,
  offerType,
  price,
  specialPrice,
  title,
  slug,
  colorTitle,
  productId,
  showConfirmOnUnfavorite,
  onProductClick,
  imageWidths,
}) {
  const { t } = useTranslation('common');
  const [isUnfavoriteModalOpen, setIsUnfavoriteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const favoriteIdsAuthenticated = useSelector(favoritesSelectors.selectFavoritesIdsAuthenticated);
  const favoriteIdsUnuthenticated = useSelector(
    favoritesSelectors.selectFavoritesIdsUnauthenticated
  );

  const isUserLogged = useSelector(authSelectors.selectIsUserLogged);
  const productRef = useRef();
  const wasMeasuredRef = useRef(false);

  const isFavoritedInStore = favoriteIdsAuthenticated.includes(productColorId);
  const isFavoritedInLocalStorage = favoriteIdsUnuthenticated.includes(productColorId);
  const isFavorited = isUserLogged ? isFavoritedInStore : isFavoritedInLocalStorage;

  const favorite = useCallback(() => {
    if (isUserLogged) {
      if (!isFavoritedInStore) {
        dispatch(favoritesThunks.favorite(productColorId));
      }
    } else if (!isFavoritedInLocalStorage) {
      dispatch(favoritesActions.favoriteUnauthenticated(productColorId));
    }
    measureAddToFavorites(specialPrice || price, {
      color_id: productColorId,
      id: productId,
      name: title,
      price: specialPrice || price,
      productId,
      variant: colorTitle,
      specialPrice,
      quantity: 1,
      ordinaryPrice: price,
    });
  }, [dispatch, productColorId, isUserLogged, isFavoritedInLocalStorage, isFavoritedInStore]);

  const unfavorite = useCallback(() => {
    if (isUserLogged && isFavoritedInStore) {
      dispatch(favoritesThunks.unfavorite(productColorId));
    } else if (isFavoritedInLocalStorage) {
      dispatch(favoritesActions.unfavoriteUnauthenticated(productColorId));
    }
  }, [dispatch, productColorId, isUserLogged, isFavoritedInLocalStorage, isFavoritedInStore]);

  const toggleModal = useCallback(() => {
    setIsUnfavoriteModalOpen(true);
  }, []);

  const handleFavouriteClick = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();

      if (isFavorited) {
        if (showConfirmOnUnfavorite) {
          toggleModal();
        } else {
          unfavorite();
        }
      } else {
        favorite();
      }
    },
    [favorite, isFavorited, showConfirmOnUnfavorite, toggleModal, unfavorite]
  );

  const handleProductLinkClick = () => {
    onProductClick();

    measureProductClick({
      color_id: productColorId,
      id: productId,
      name: title,
      price: specialPrice || price,
      productId,
      variant: colorTitle,
      specialPrice,
      quantity: 1,
      ordinaryPrice: price,
    });
  };

  useEffect(() => {
    const domElement = productRef.current;
    const onIntersectionChange = entries => {
      const entry = entries[0];

      if (entry.isIntersecting && !wasMeasuredRef.current) {
        wasMeasuredRef.current = true;

        measureProductCardView({
          color_id: productColorId,
          id: productId,
          name: title,
          price: specialPrice || price,
          productId,
          variant: colorTitle,
          specialPrice,
          quantity: 1,
          ordinaryPrice: price,
        });
      }
    };

    const observer = new IntersectionObserver(onIntersectionChange, { threshold: 0.6 });

    observer.observe(domElement);

    return () => {
      observer.unobserve(domElement);
    };
  }, []);

  return (
    <React.Fragment>
      {showConfirmOnUnfavorite && (
        <Modal
          isOpen={isUnfavoriteModalOpen}
          onClose={() => setIsUnfavoriteModalOpen(false)}
          withCloseButton={false}
          timeout={300}
          mountOnEnter
          unmountOnExit
          appear
          exit
          enter
        >
          <ConfirmationDialog
            onDismiss={() => setIsUnfavoriteModalOpen(false)}
            onConfirm={() => {
              unfavorite();
              setIsUnfavoriteModalOpen(false);
            }}
            title={t('favorites.unfavorite.title', 'Produkt Entfernen?')}
            description={t(
              'favorites.unfavorite.description',
              'Möchtest du dieses Produkt wirklich aus Deinen Favoriten entfernen?'
            )}
            dismissText={t('favorites.unfavorite.dismiss', 'Abbrechen')}
            confirmText={t('favorites.unfavorite.confirm', 'Entfernen')}
          />
        </Modal>
      )}
      <ProductBox
        innerRef={productRef}
        image={image}
        isFavorited={isFavorited}
        offerType={offerType}
        price={price}
        productColorId={productColorId}
        slug={slug}
        specialPrice={specialPrice}
        title={title}
        toggleFavorite={handleFavouriteClick}
        publishedUntil={publishedUntil}
        onProductLinkClick={handleProductLinkClick}
        imageWidths={imageWidths}
      />
    </React.Fragment>
  );
}

Product.propTypes = {
  colorTitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  offerType: PropTypes.oneOf(Object.keys(OFFER_TYPES)),
  price: PropTypes.number.isRequired,
  productColorId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  publishedUntil: PropTypes.string,
  showConfirmOnUnfavorite: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  specialPrice: PropTypes.number,
  title: PropTypes.string.isRequired,
  onProductClick: PropTypes.func,
  imageWidths: PropTypes.arrayOf(PropTypes.number),
};

Product.defaultProps = {
  offerType: undefined,
  publishedUntil: undefined,
  showConfirmOnUnfavorite: false,
  specialPrice: 0,
  onProductClick: () => {},
  imageWidths: undefined,
};

export default Product;
