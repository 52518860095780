import styled from 'styled-components';

import {
  media, getTypographyStyle, getGridBase, getThemeColor,
} from '@utils/styled';

import Typography from '@common/components/Typography';
import { LAYOUT_COLUMN, NEGATIVE_STICKY_HEADER_HEIGHT } from '@common/constants/ui';

export const PriceSmall = styled(Typography).attrs({ variant: 'body2' })`
  margin: 0;
  color: ${getThemeColor('bluegrey.60')};
`;

export const PriceCrossed = styled(PriceSmall)`
  position: relative;
  width: min-content;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 2px;
    left: -1px;
    width: calc(100% + 2px);
    height: 1px;
    margin: auto;
    background: ${getThemeColor('bluegrey.60')};
  }
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  customAs: 'h3',
  variant: theme.layoutPreference === LAYOUT_COLUMN ? 'h5' : 'paragraph2',
}))`
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 3)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 6)};
  font-weight: 700;

  ${media.from480up`
    ${getTypographyStyle('h5')};
  `}
`;

export const Container = styled.div`
  margin-top: ${({ expiredProduct }) => (expiredProduct ? 0 : NEGATIVE_STICKY_HEADER_HEIGHT)}rem;
  ${({ theme, isShopEventOpen }) =>
    isShopEventOpen && theme.getFluidSpacing('margin-bottom', 'section', 0)};

  ${media.from880up`
    margin-top: 0;
  `}
`;

export const Article = styled.article`
  ${getGridBase(12)};
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)}
  width: 100%;
`;

export const ExpiredProductArticle = styled.article`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;

  ${media.from640up`
    flex-direction: row;
  `}
`;
